@keyframes grow {
    0% {
        height: 5%;
        opacity: 1;
    }
    100% {
        height: 100%;
        opacity: 1;
    }
}
