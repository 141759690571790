.wrapper {
    max-width: calc(960px + 2 * var(--default-desktop-padding));
    margin: 0 auto;
    padding: 120px var(--default-desktop-padding) 120px;

    @media screen and (max-width: 600px) {
        padding: 40px var(--default-mobile-padding) 80px;
    }

    @media screen and (max-width: 360px) {
        padding: 40px 20px;
    }

    overflow: hidden;
}

h1.title {
    margin: 0 0 48px;
    font-size: 72px;
    color: var(--color-black);

    @media screen and (max-width: 740px) {
        font-size: clamp(32px, 5vw + 1rem, 72px);
    }
}

h2.title {
    margin: 0 0 32px;
    font-size: 48px;
    color: var(--color-black);

    @media screen and (max-width: 600px) {
        margin: 0 0 40px;
        font-size: 28px;
        text-align: center;
        word-break: break-word;
    }
}

.body {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;

    @media screen and (max-width: 900px) {
        font-size: 18px;
        line-height: 24px;
    }

}
