@import '../Generic.module';

.wrapper {
    max-width: var(--default-max-width);
    margin: 0 auto;
    padding: 200px var(--default-desktop-padding) 120px;
    display: grid;
    column-gap: 40px;
    grid-template-columns: 2fr auto 500px;
    grid-template-rows: 0fr;
    grid-template-areas:
        "title . image"
        "text . image";

        h1.title {
            grid-area: title;
            height: auto;
            max-width: 660px;
        }

        .text {
            grid-area: text;
            max-width: 660px;
        }

        .image-wrapper {
            grid-area: image;
        }

    @media screen and (max-width: 1300px) {
        grid-template-columns: auto;
        grid-template-rows: 0fr;
        grid-template-areas:
        "title"
        "image"
        "text";
    }

    @media screen and (max-width: 600px) {
        //min-height: min(700px, 80vh);
        padding: calc(var(--default-mobile-header-height) + 15%) var(--default-mobile-padding) 80px;
    }

    @media screen and (max-width: 400px) {
        //min-height: auto;
        padding: calc(var(--default-mobile-header-height) + 15%) 20px 40px;
    }
}

h1.title {
    margin: 0;

    @media screen and (max-width: 1300px) {
        text-align: center;
        margin: 0 auto 40px;
        max-width: unset;
    }
}

h1.title.large {
    font-size: 72px;

    @media screen and (max-width: 740px) {
        font-size: clamp(32px, 5vw + 1rem, 72px);
    }
}

h1.title.small {
    font-size: 52px;

    @media screen and (max-width: 740px) {
        font-size: clamp(32px, 4vw + 1rem, 56px);
    }
}

h1.title.default {
    font-size: 64px;

    @media screen and (max-width: 740px) {
        font-size: clamp(32px, 4.5vw + 1rem, 64px);
    }
}

.text {
    p {
        margin: 12px 0;
    }

    @media screen and (max-width: 1300px) {
        margin: 32px auto 0;
        width: auto;
        max-width: 634px;
    }
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
    flex-wrap: wrap;
    > * {
        flex-grow: 1;
        text-align: center;
    }

    @media screen and (max-width: 1300px) {
        justify-content: space-between;
    }
}

.body {
    font-weight: 200;
}

.image-wrapper {
    display: flex;
    order: 3;

    img {
        width: 100%;
        max-width: 500px;
        max-height: 500px;
        object-fit: contain;
        object-position: top;
    }

    @media screen and (max-width: 1300px) {
        justify-content: center;
        order: 2;
        max-width: unset;

        img {
            width: 100%;
            height: auto;
            max-width: 634px;
            display: block;
        }
    }
}
