.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: min(60vh, 540px);
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 50%;
        width: 100%;
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 86%);
        z-index: 2;

        @media screen and (max-width: 1050px) {
            height: 30%;
        }
    }

    video {
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        background-color: var(--color-black);

        @media screen and (max-width: 800px) {
            object-position: -20px;
        }
    }
}

.overlay {
    max-width: var(--default-max-width);
    padding: 80px var(--default-desktop-padding);
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
        padding: 60px var(--default-mobile-padding);
    }
}

.text {
    z-index: 5;

    .title {
        font-size: 46px;
        color: var(--color-white);
        margin-bottom: 40px;
    }

    .body {
        font-size: 16px;
        color: var(--color-white);
        max-width: 500px;
        margin-bottom: 80px;
        font-weight: 300;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p a {
        color: var(--link-light);
    }

    @media screen and (max-width: 1050px) {
        .title {
            margin-top: 80px;
            font-size: 32px;
        }

        .body {
            font-size: 16px;
            margin-bottom: 40px;
            min-height: 100px;
        }
    }
}
