@media screen and (min-width: 1101px) {
    .timeline-wrapper {
        padding: 40px var(--default-desktop-padding);
        max-width: var(--default-max-width);
        margin: 0 auto;

        h2 {
            text-align: center;
            font-size: 40px;
        }

        .text {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
        }

        .timeline {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 220px;
            margin-bottom: 200px;

            .step {
                position: relative;
                background-clip: content-box;
                width: calc(100% / 6);
                height: 40px;
                background-color: var(--color-sky);
                margin-bottom: 20px;
                padding-top: 20px;
                border-left: var(--color-coal) 1px solid;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -5px;
                    width: 9px;
                    height: 9px;
                    background-color: var(--color-coal);
                    border-radius: 50%;
                }

                &:nth-child(even) {
                    margin-bottom: 0;
                    padding-top: 0;
                    margin-top: 20px;
                    padding-bottom: 20px;
                    background-color: var(--color-sand-light);

                    .step-inner {
                        top: 40px;
                    }

                    &::before {
                        top: unset;
                        bottom: 0;
                    }
                }

                .step-inner {
                    position: absolute;
                    top: -170px;
                    width: min(16vw, 201px);
                    margin: 20px 0;

                    .step-title {
                        text-transform: uppercase;
                        font-family: Muli, sans-serif !important;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        margin: 6px 0;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 200;
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .timeline-wrapper {
        padding: 40px var(--default-desktop-padding);

        h2 {
            text-align: center;
            font-size: 32px;
            margin-top: 0;
        }

        .text {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
        }

        .timeline {
            display: grid;
            place-content: flex-start;
            align-items: center;
            padding-left: 25%;
            margin-top: 70px;
            margin-bottom: 8px;

            .step {
                width: 80%;
                min-height: 160px;

                .step-title {
                    position: relative;
                    margin: 0;
                    font-family: Muli, sans-serif !important;
                    font-size: 16px;
                    font-weight: 600;

                    &:before {
                        content: '';
                        position: absolute;
                        left: -60px;
                        width: 20px;
                        height: 20px;
                        top: 4px;
                        background-color: var(--color-sky);
                        border-radius: 50%;
                    }
                }

                .step-body {
                    position: relative;
                    margin: 0;
                    padding-top: 5px;
                    font-size: 14px;

                    &:before {
                        content: '';
                        position: absolute;
                        left: -52px;
                        width: 4px;
                        height: 120px;
                        top: 10px;
                        background-color: var(--color-sky);
                        border-radius: 4px;
                    }
                }

                &:nth-child(odd) {
                    .step-title:before {
                        background-color: var(--color-sand-light);
                    }

                    .step-body:before {
                        background-color: var(--color-sand-light);
                    }
                }
            }
        }

        .step:last-child {
            .step-body::before {
                display: none;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .timeline-wrapper {
            padding: 40px var(--default-mobile-padding);
        }
    }
}
