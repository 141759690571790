.show-more {
    margin: 48px 0 0;

    h3 {
        margin: 0 0 10px;
        font-size: 28px;
        font-weight: 400;
        font-family: Muli, sans-serif !important;
    }

    .container {
        color: var(--link-dark);
        font-size: 16px;
        font-weight: 300;
        display: flex;
        gap: 10px;
        width: unset;

        a {
            color: var(--link-dark);
            text-decoration: underline;
        }

        svg {
            color: var(--color-black);
        }
    }

    @media screen and (max-width: 750px) {
        margin: 0 auto 0 0;

        h3 {
            display: none;
        }

        svg {
            display: none;
        }

        .container {
            border-radius: 4px;
            background-color: var(--color-sky-darker);
            padding: 8px 24px;
            justify-content: center;

            a {
                text-decoration: unset;
                color: var(--color-white);
            }
        }
    }
}
