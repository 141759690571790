.wrapper {
    section.discolored {
        background: var(--color-egg-white);
    }

    section.odd {
        background: var(--color-egg-white);
    }

    section.even {
        background: var(--color-eggshell);
    }
}

.big-hook {
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 60px;
    row-gap: 20px;
    justify-content: flex-start;
    padding: 60px var(--default-desktop-padding) 60px;

    h2, p {
        color: var(--color-coal);
    }

    .title {
        max-width: 480px;
        flex-basis: 50%;
        flex-grow: 1;
        color: var(--color-coal);

        font-style: normal;
        line-height: normal;

        h2 {
            font-size: 48px;
            margin: 0;

            @media screen and (max-width: 500px) {
                font-size: 26px;
            }
        }

        @media screen and (max-width: 756px) {
            max-width: 100%;
        }
    }

    .text {
        max-width: 500px;
        flex-basis: 50%;
        flex-grow: 1;

        p {
            color: var(--color-coal);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 756px) {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 756px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
        padding: 50px var(--default-mobile-padding);
    }
}

.small-hook {
    max-width: var(--default-max-width);
    margin: 0 auto;
    padding: 80px var(--default-desktop-padding);
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    h2, p {
        color: var(--color-coal);
    }

    .title {
        flex-basis: 50%;
        max-width: 500px;
        margin: 0;
    }

    .text {
        flex-basis: 50%;
        max-width: 620px;

        p {
            color: var(--color-coal);
            font-size: 16px;
            font-style: normal;
            font-weight: 200;
            line-height: normal;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        .button {
            display: inline-block;
            border-radius: 4px;
            background: var(--color-sky-darker);
            padding: 8px 24px 12px;
            color: var(--color-white);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            margin-top: 16px;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 1150px) {
        padding: 50px var(--default-desktop-padding);
        margin: 0;
        max-width: unset;
        flex-direction: column;
        gap: 20px;

        .title {
            max-width: unset;

            h2 {
                font-size: 22px;
            }
        }

        .text {
            max-width: unset;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 50px var(--default-mobile-padding);
    }
}

.highlight-container {
    background-color: #F8F8F8;
    padding: 35px;

    div > * {
        margin: 10px !important;
    }
}

.slim {
    max-width: var(--slim-max-width);
    padding: 0 var(--default-desktop-padding);
}

.default {
    max-width: var(--default-max-width);
    padding: 0 var(--default-desktop-padding);
}

.center {
    max-width: var(--default-max-width);
    padding: 0 var(--default-desktop-padding);

    h2 {
        text-align: center;
    }
}

.wide {
    max-width: var(--default-max-width);

    @media screen and (max-width: 600px) {
        padding: 0 !important;
    }
}

.scheduler {
    margin: 80px auto;

    h2 {
        margin-top: 50px;
    }

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }
}

.not-found {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 91px;
    padding: 100px var(--default-desktop-padding) 120px;
    text-align: center;
    height: 50vh;
    min-height: 500px;

    h1 {
        font-family: Muli, sans-serif !important;
        font-size: 40px;
        font-weight: 400;
        margin: 0;
    }

    h2 {
        font-family: Muli, sans-serif !important;
        font-size: 24px;
        font-weight: 300;
    }

    p {
        font-family: Muli, sans-serif !important;
        font-size: 16px;
        font-weight: 400;
    }

    .button {
        all: initial;
        display: inline-block;
        background-color: var(--color-sky-darker);
        color: var(--color-white);
        padding: 8px 16px;
        margin: 16px;
        border-radius: 4px;
        cursor: pointer;
        font-family: Muli, sans-serif;
        font-size: 16px;

        &:hover {
            background-color: var(--color-sky-dark);
        }
    }

    @media screen and (max-width: 992px) {
        margin-top: 76.5px;
    }
}

