.wrapper {
    background-color: var(--color-white);
}

.text {
    font-family: var(--header-font-family);
    margin: 0 auto;
    padding: 80px;
    max-width: 900px;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    text-align: center;

    @media screen and (max-width: 750px) {
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        padding: 80px 40px;
    }
}
