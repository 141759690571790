.content {
    max-width: var(--slim-max-width);
    margin: 80px auto;
    padding: 0 var(--default-desktop-padding) 0;
    min-height: 300px;

    h1 {
        text-align: center;
        font-size: 48px;
        line-height: normal;
        margin: calc(80px + var(--default-header-height)) 0 80px;

        @media screen and (max-width: 992px) {
            margin: calc(80px + var(--default-mobile-header-height)) 0 80px;
        }
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 16px;
    }

    h2, h3, h4, h5, h6 {
        margin: 40px 0 24px;
    }

    & > img {
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    ol, ul {
        margin: 16px 0 24px;

        p {
            margin: 8px 0 16px;
        }
    }

    p {
        margin: 8px 0 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 2;

        @media screen and (max-width: 992px) {
            margin: 40px auto;
        }
    }

    blockquote {
        background-color: var(--color-grey-lighter);
        color: var(--color-sand-dark);
        text-align: center;
        max-width: 700px;
        padding: 40px;
        margin: 0 0 24px;
        border-radius: 4px;

        i {
            font-style: italic;
        }

        * {
            font-style: normal;
            font-weight: 300;
        }

        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            text-align: center;
            color: var(--color-coal);
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding) 0;

        h1 {
            font-size: 42px;
            margin: 0 0 40px;
        }
    }
}
