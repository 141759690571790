@import '../../Keyframes';

.teaser-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 64px;

    @media screen and (max-width: 1200px) {
        gap: 40px;
    }

    @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
    }
}

.teaser {
    padding: 40px;
    &:nth-child(even) {
        background: radial-gradient(87.52% 87.52% at 24.12% 26.54%, #F9F7F0 60.93%, #EEEFEE 100%);
    }
    &:nth-child(odd) {
        background: radial-gradient(69.59% 69.59% at 69.26% 44.04%, #F9F7F0 60.93%, #EEEFEE 100%);
    }
    min-height: 350px;
    display: grid;
    grid-template-rows: auto auto 2fr;
    gap: 12px;

    animation: slideInFromBottom 1s ease-in-out;

    h4.teaser-title {
        font-family: Muli, sans-serif !important;
        font-size: 24px;
        line-height: 32px;
        color: var(--color-black);
        margin: 0;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-black);
        font-weight: 200;

        @media screen and (max-width: 600px) {
            font-weight: 300;
        }
    }

    .logotype {
        padding: 0 16px;
        background-color: var(--color-white);
        width: fit-content;
        font-size: 0;
        border-radius: 4px;

        img {
            object-fit: contain;
        }
    }

    .teaser-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 40px;
    }

    .teaser-button {
        border: 1px solid var(--color-black);
        border-radius: 4px;
        padding: 6px 12px;
        width: fit-content;
        display: inline-block;
        color: var(--color-black);
        text-decoration: none;

        &:hover {
            background-color: var(--color-black);
            color: var(--color-white);
        }
    }

    .teaser-link {
        display: flex;
        justify-content: center;
        gap: 12px;
        font-size: 14px;
        line-height: 20px;
    }

    @media screen and (max-width: 600px) {
        min-height: 430px;
        animation: fadeInLate 0.8s ease-in;
    }
}
