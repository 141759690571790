@import 'Questionnaire.module';

.animated-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 32px;
    z-index: 2;

    .correct {
        color: var(--color-orange);
        font-size: 36px;
    }

    .incorrect {
        color: var(--color-white);
        font-size: 40px;
        margin-top: -20px;
    }

    p {
        margin: 0;
        font-size: 24px;
    }
}

.cta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    z-index: 2;
    flex-wrap: wrap;
    max-width: 1100px;
    transition: opacity 1s ease, transform 1s ease;

    &.fade-out {
        opacity: 0 !important;
        transform: translateY(100px);
    }

    .title {
        text-align: start;
    }

    .column-left {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        gap: 8px;
        flex-basis: 14%;
        margin-top: 12px;
        font-size: 20px;

        .correct {
            color: var(--color-orange);
            font-size: 24px;
        }

        .incorrect {
            color: var(--color-white);
            font-size: 28px;
            margin-top: -16px;
        }

        @media screen and (max-width: 900px) {
            display: none;
        }
    }

    .column-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-basis: 70%;

        @media screen and (max-width: 900px) {
            flex-basis: 100%;
        }
    }

    .button-container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
        }
    }
}
