.wrapper {
    padding: 80px var(--default-desktop-padding) 120px;
    min-height: 500px;
    margin: 0;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;
    object-fit: cover;

    @media screen and (max-width: 600px) {
        padding: 60px var(--default-mobile-padding);
        min-height: 600px;
    }

    h2, h3, p, div {
        color: var(--color-white) !important;
    }

    .stepper-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        border-radius: 4px;
        background-color: var(--color-white);
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        color: var(--color-black) !important;
        border: unset;

        &:hover {
            background-color: var(--color-sand);
        }
    }

    .filled-button {
        padding: 8px 16px;
        min-width: 300px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 20px;
        background-color: var(--color-sand-light);
        text-decoration: none;
        color: black !important;
        text-align: center;
        cursor: pointer;
        border: unset;
    }

    .hollow-button {
        padding: 8px 16px;
        min-width: 300px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 20px;
        background-color: transparent;
        border: 2px solid var(--color-white);
        text-decoration: none;
        color: var(--color-white) !important;
        text-align: center;
        cursor: pointer;
        border: unset;
    }
}

.title {
    margin: 0;
    font-size: 36px;
    font-weight: 500 !important;
    line-height: 120%;
    text-align: center;

    // currently only supported on chromium https://caniuse.com/mdn-css_properties_text-wrap_pretty
    text-wrap: pretty;

    // apparently the same as text-wrap: pretty but for firefox and safari
    text-wrap-style: pretty;

    @media screen and (max-width: 900px) {
        font-size: 26px;
    }
}

.body {
    font-size: 18px;
    flex-basis: 80%;
    font-weight: 300;
    line-height: 140%;

    & > p {
        margin-top: 0;
    }

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: none;
}
