@import 'Questionnaire.module';

.contact-form {
    display: flex;
    flex-direction: row;
    z-index: 2;
    column-gap: 80px;
    row-gap: 16px;
    max-width: 1100px;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
    transition: opacity 1s ease, transform 1s ease;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    textarea:focus, input:focus{
        outline: none;
    }

    .title {
        text-align: start;
        font-size: 80px;
        margin-top: 16px;

        @media screen and (max-width: 900px) {
            font-size: 48px;
        }
    }

    .body {
        font-size: 24px;

        @media screen and (max-width: 900px) {
            font-size: 20px;
        }
    }

    &.fade-out {
        opacity: 0 !important;
        transform: translateY(100px);
    }


    .column-left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-basis: 55%;

        @media screen and (max-width: 900px) {
            flex-basis: 100%;
        }
    }

    .column-right {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 20px;

        @media screen and (max-width: 900px) {
            flex-basis: 100%;
        }
    }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-basis: 40%;
    }

    .input {
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 500;
        color: black !important;
        border: none;
    }

    label {
        margin-top: 16px;
    }

    .textarea {
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 500;
        color: black !important;
        border: none;
        resize: none;
    }

    .button {
        margin-top: 12px;
        padding: 8px 16px;
        border-radius: 4px;
        flex-grow: 1;
        font-size: 20px;
        font-weight: 500;
        background-color: var(--color-sand-light);
        color: black !important;
        cursor: pointer;
    }
}
