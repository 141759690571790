.question-submitted {
    margin-top: 20px;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 var(--default-desktop-padding);
    z-index: 2;
    flex-grow: 1;

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }

    .title {
        @media screen and (max-width: 900px) {
            margin: 20px 0 0;
        }
    }

    .body {
        font-size: 24px;

        @media screen and (max-width: 900px) {
            font-size: 20px;
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 20px;
        width: 100%;
    }
}

.animated-sent {
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    position: absolute;
}
