@import '../Generic.module';

.columns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    > * {
        flex-basis: 43%;
    }

    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;

        > * {
            flex-basis: unset;
        }
    }
}

.image-wrapper {
    display: flex;
    justify-content: right;
    width: 100%;
    border-radius: 4px;
    margin: 40px 0 24px;

    img {
        object-fit: cover;
    }

    @media screen and (max-width: 900px) {
        justify-content: center;
        margin: 0 0 24px;

        img {
            aspect-ratio: 4/2;
            width: 100%;
            height: auto;
        }
    }
}
