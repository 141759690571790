@import 'Questionnaire.module';

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-top: 20px;
    max-width: 1100px;

    .children {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        .correct {
            color: var(--color-orange);
            font-size: 32px;
        }
        .incorrect {
            color: var(--color-white);
            margin-top: -18px;
            font-size: 36px;
        }
    }

    .star-container {
        position: absolute;
        bottom: 120px;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 900px) {
            bottom: 60px;
        }
    }

    .button-container {
        margin-top: 120px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 32px;
        row-gap: 16px;

        .button {
            flex-basis: 45%;
            flex-grow: 1;
            padding: 12px 16px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 24px;
            background-color: var(--color-sand-light);
            text-decoration: none;
            color: black !important;
            text-align: center;
            cursor: pointer;
            border: unset;

            &:hover {
                background-color: var(--color-sand);
            }

            @media (max-width: 900px) {
                flex-basis: 100%;
                font-size: 20px;
                padding: 8px 12px;
            }
        }
    }
}
