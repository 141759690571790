.pagination-wrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 0;

    button {
        all: initial;
        background-color: var(--color-sky-darker);
        color: var(--color-white);
        padding: 16px;
        margin: 0 15px;
        border-radius: 4px;
        cursor: pointer;
        font-family: Muli, sans-serif;
        font-size: 16px;

        &:hover {
            background-color: var(--color-sky-dark);
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    color: var(--color-black);
    margin-top: 40px;
}
