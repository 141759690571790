@import '../Keyframes';
@import '../Hero/HeroGeneric.module';

.hero {
    height: min(100svh, 850px);

    video {
        height: calc(100% - var(--default-desktop-padding));
        width: 100vw;
        object-fit: cover;

        @media screen and (max-width: 1050px) {
            height: min(70svh, 470px);
            position: absolute;
            top: 0;
        }
    }

    @media screen and (max-width: 1050px) {
        height: unset;
        max-height: unset;
    }
}

.text {
    animation: fadeIn 1.2s ease;
    margin-bottom: 100px;

    .title {
        font-size: 46px;
        color: var(--color-white);
        margin-bottom: 40px;
    }

    .body {
        font-size: 16px;
        color: var(--color-white);
        max-width: 500px;
        margin-bottom: 80px;
        font-weight: 300;
    }

    @media screen and (max-width: 1050px) {
        margin-bottom: 0;
        animation: fadeInMobile 0.4s ease;
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 80px;

    @media screen and (max-width: 1200px) {
        gap: 24px;
    }

    @media screen and (max-width: 1050px) {
        margin-top: 160px;
        grid-template-columns: 1fr;
        gap: 18px;
    }
}

.card {
    padding: 24px 24px 80px 24px;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(10px);
    color: var(--color-white);

    p a {
        color: var(--link-light);

        @media screen and (max-width: 1050px) {
            color: var(--link-dark);
        }
    }

    &:nth-child(1) {
        animation: slideInFromLeft 0.8s ease;
    }
    &:nth-child(2) {
        animation: slideInFromTop 0.8s ease;
    }
    &:nth-child(3) {
        animation: slideInFromRight 0.8s ease;
    }

    h2 {
        color: var(--color-white);
        font-size: 32px;
        font-family: Muli, sans-serif !important;
        margin: 0;
        line-height: 40px;

        @media screen and (max-width: 1050px) {
            color: var(--color-black);
        }
    }

    .card-body {
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;

        @media screen and (max-width: 1050px) {
            color: var(--color-black);
        }
    }

    @media screen and (max-width: 1050px) {
        min-height: unset;
        background-color: unset;
        padding: unset;

        h2 {
            line-height: 30px;
        }

        &:nth-child(1) {
            animation: slideInFromLeftMobile 0.7s ease;
        }
        &:nth-child(2) {
            animation: slideInFromLeftMobile 0.7s ease;
        }
        &:nth-child(3) {
            animation: slideInFromLeftMobile 0.7s ease;
        }
    }
}

.icons {
    font-size: 44px;
    margin-top: 14px;
    display: flex;
    gap: 20px;

    a {
        color: var(--color-white);

        &:hover {
            color: var(--color-sand);
        }

        @media screen and (max-width: 1050px) {
            color: var(--color-black);
        }
    }
}

.small-hook {
    background-color: var(--color-grey-light);
    background-clip: padding-box;
    margin-bottom: -40px;
    padding-bottom: 40px;
}

.small-hook-content {
    max-width: var(--default-max-width);
    margin: 0 auto;
    padding: 80px var(--default-desktop-padding) 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    h2, p {
        color: var(--color-coal);
    }

    .title {
        max-width: 700px;
        margin: 0;
    }

    .body {
        max-width: 600px;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
    }

    @media screen and (max-width: 1050px) {
        padding: 50px var(--default-desktop-padding);
        margin: 0;
        flex-direction: column;

        .title {
            h2 {
                font-size: 22px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        padding: 40px var(--default-mobile-padding);
    }
}
