.highlights {
    max-width: var(--default-max-width);
    padding: 80px var(--default-desktop-padding) 120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;

    p, h2 {
        margin: 0;
    }

    .columns {
        display: flex;
        column-gap: 80px;

        p {
            color: var(--Grey, #62615C);
            font-size: 16px;
            font-weight: 300;
            padding-bottom: 16px;
            max-width: 800px;

            b {
                font-weight: 600;
            }
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .grid {
        display: flex;
        column-gap: 80px;
        row-gap: 40px;
        flex-wrap: wrap;

        & > div {
            flex-basis: calc(33% - 160px / 3);
            padding: 8px 0;
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > * {
                margin: 0;
            }

            h3 {
                font-family: var(--primary-font-family) !important;
                font-size: 20px;
                font-weight: 400;
            }

            p {
                margin-top: 8px;
                font-size: 14px;
                font-weight: 300;
            }

            @media screen and (max-width: 992px) {
                flex-basis: calc(33% - 60px / 3);
            }
        }

        @media screen and (max-width: 992px) {
            gap: 20px;
            place-content: space-between;
        }
    }

    @media screen and (max-width: 756px) {
        flex-direction: column;
        padding: 50px var(--default-desktop-padding);
        margin: 0;

        .columns {
            flex-direction: column;
            column-gap: 0;
            row-gap: 24px;
            flex-wrap: wrap;

            & > * {
                width: unset;
            }
        }

        .body {
            flex-basis: 100%;

            p:first-child {
                margin-top: 10px;
            }
        }

        .grid {
            flex-direction: column;
            gap: 24px;
            margin: 20px 0 0;

            p {
                margin-top: 5px;
            }

            & > div {
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 0;

                color: var(--color-coal);
            }
        }
    }

    @media screen and (max-width: 600px) {
        padding: 50px var(--default-mobile-padding);
        gap: 24px;
    }
}
