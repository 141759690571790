@import '../Page.module';
@import '../Hero/HeroGeneric.module';
@import '../Keyframes';

.content {
    text-align: center;
    margin: 130px auto 60px;
    padding: 0 var(--default-desktop-padding);

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }
}

.overlay {
    @media screen and (max-width: 600px) {
        padding: var(--default-mobile-padding);
    }
}

.cases-wrapper {
    max-width: var(--default-max-width);
    margin: 80px auto 150px;
    padding: 0 var(--default-desktop-padding);

    @media screen and (max-width: 1050px) {
        margin: 40px auto 40px;
        padding: 0;
    }
}

.text {
    animation: fadeIn 1.5s ease;

    .body {
        max-width: 600px;
    }

    @media screen and (max-width: 1050px) {
        .title {
            font-size: 46px;
            margin-top: 0;
        }
    }
}
