@import './Keyframes';

.background-wrapper {
    background-color: #F7F7F7;
    background-size: cover;
    box-sizing: content-box;
    background-position: center;
}

.wrapper {
    padding: 80px var(--default-desktop-padding) 120px;
    max-width: var(--default-max-width);
    margin: 0 auto;
    display: grid;
    grid-template-areas:
    "text graph"
    "link graph";
    grid-template-rows: min-content auto;
    gap: 40px 10px;
    min-height: 550px;

    @media screen and (max-width: 1100px) {
        grid-template-areas: "text" "graph" "button";
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 40px var(--default-desktop-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }

    .text {
        grid-area: text;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 40px 10px;

        @media screen and (max-width: 1100px) {
            min-width: unset;
        }
    }

    .graph {
        grid-area: graph;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 0;

        @media screen and (max-width: 1100px) {
            margin-bottom: 0;
            width: 100%;
            justify-content: center;
        }
    }

    .link {
        grid-area: link;

        a {
            display: inline-block;
            padding: 12px 20px;
            border: 1px black solid;
            border-radius: 60px;
            font-weight: 400;
            font-size: 20px;
            background-color: #F7F7F7;
            text-decoration: none;
            color: black;
        }

        a:hover {
            background-color: black;
            color: white;
        }

        @media screen and (max-width: 1100px) {
            padding-top: 40px;
            margin: 10px 0 80px;
        }
    }

    .bar-graph {
        display: flex;
        flex-direction: row;
        gap: 40px;
        opacity: 0;
        height: 100%;
    }

    .grow {
        animation: grow 0.8s ease forwards;
    }

    .bar-graph_bar {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        gap: 8px;

        @media screen and (max-width: 1100px) {
            min-height: 300px;
            height: unset;
        }
    }

    .bar-graph_value {
        background-color: var(--color-sand);
        width: 70px;
    }

    .bar-graph_title {
        text-align: center;
        margin-top: 5px;
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
    }

    h2 {
        font-size: 28px;
        font-family: Muli, sans-serif !important;
        font-weight: 700;
        margin: 0;

        @media screen and (max-width: 1100px) {
            margin: 40px 0 0;
        }
    }

    .body {
        font-size: 20px;
        font-weight: 200;

        p {
            margin: 0;
        }

        @media screen and (max-width: 1100px) {
            margin-bottom: 10px;
        }
    }
}
