@import 'Questionnaire.module';

.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-top: 20px;
    max-width: 1100px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 60px;

        @media screen and (max-width: 900px) {
            gap: 20px;
        }
    }

    .explanation {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
    }

    .check {
        color: var(--color-white);
        font-size: 40px;
        margin-top: 10px;
    }
    .correct {
        margin-top: 10px;
    }
    .incorrect {
        color: var(--color-white);
        font-size: 46px;
        margin-top: -22px;
    }

    .children {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 80px;

        @media screen and (max-width: 900px) {
            margin-top: 40px;
        }

        .correct {
            color: var(--color-orange);
            font-size: 32px;
        }
        .incorrect {
            color: var(--color-white);
            font-size: 36px;
            margin-top: -10px;
        }
    }

    .star-container {
        position: absolute;
        bottom: 120px;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 900px) {
            bottom: 60px;
        }
    }

    .latest-answer {
        display: flex;
        flex-direction: column;
        flex-basis: 15%;
        align-items: center;

        .answer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h3 {
            margin: 20px 0 0 0;
        }
    }
}
