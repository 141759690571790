@import '../Generic.module';

.item {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;

    @media screen and (max-width: 900px) {
        font-size: 18px;
        line-height: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: 0;
}
