.wrapper {
  margin: 0 auto;
  display: flex;
  background-color: var(--color-white);
}

.wrapper > div {
  width: 50%;
  padding: 80px;

  @media screen and (max-width: 1200px) {
    padding: 40px;
  }
}

.wrapper > div > div {
  max-width: calc((1320px/2) - 80px);
}

.wrapper > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.wrapper > div:last-child {
  display: flex;
  justify-content: flex-start;
}

.body {

  h2 {
    color: var(--color-black);
    font-family: var(--primary-font-family) !important;
    font-size: 28px;
    font-weight: 300;
    line-height: 35.14px;
  }

  p {
    font-size: 18px;
    font-weight: 200;
    line-height: 32px;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper > div {
    justify-content: center !important;
    width: 100%;
  }

  .wrapper > div:first-child {
    padding-bottom: 20px;
  }

  .wrapper > div:last-child {
      padding-top: 20px;
  }

}
