.hero-image {
    position: relative;
    background-position: center;
    background-color: black;
    display: flex;
    margin-bottom: 80px;
    height: 36vh;
    min-height: 310px;
    max-height: 600px;
    overflow: hidden;

    .overlay {
        max-width: var(--slim-max-width);
        padding: 0 var(--default-desktop-padding);
        width: 100%;
        margin: 0 auto;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 46px;
            display: block;
            color: #fff;
            text-shadow: 0 0 0.6em black, 0 0 0.1em black;

            @media screen and (max-width: 992px) {
                font-size: 32px;
            }
        }

        .body {
            font-size: 20px;
            font-weight: 300;
            color: #fff;

            p a {
                color: var(--link-light);
            }

            @media screen and (max-width: 992px) {
                font-size: 18px;
            }
        }

        @media screen and (max-width: 992px) {
            padding-top: 0;
            top: 80px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 var(--default-mobile-padding);
        }
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100vw;
        object-fit: cover;
        opacity: 95%;
        min-height: 310px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index: 2;
    }

    @media screen and (max-width: 992px) {
        padding-top: var(--default-mobile-padding);
    }
}
