.body {
    font-size: 16px;
    font-weight: 300;

    p:last-child {
        margin-bottom: 0;
    }
}

.bullet-points {
    p {
        margin-top: 0;
        margin-bottom: 8px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: unset;
    overflow: hidden;
    height: 100%;
    padding: 24px;
    gap: 16px;

    h3 {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 40px;
    }
}

.link {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    text-decoration: none;
    padding-top: 16px;

    &:hover {
        color: black !important;
    }
}

.icon {
    margin-left: 10px;
}
