.hero-video {
    position: relative;
    background-position: center;
    overflow: hidden;
    font-size: 0;
    background-color: #000;
    height: 100svh;

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 50%;
        width: 100%;
        top: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index: 2;
    }

    .overlay {
        position: absolute;
        padding: 0 var(--default-desktop-padding);
        bottom: 80px;
        right: 150px;

        h1 {
            color: #fff;
            text-shadow: 0 0 30px rgba(0, 0, 0, 0.15), 0 0 50px rgb(0,0,0, 0.15);
            font-size: 36px;
            max-width: 904px;
            margin-bottom: 10px;

            @media screen and (max-width: 600px) {
                font-size: 32px;
            }
        }

        p {
            color: #ececec;
            text-shadow: 0 0 10px rgb(0, 0, 0, 0.6);
            font-size: 20px;
            font-weight: 300;
            margin: 0;

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }

        @media screen and (max-width: 992px) {
            left: 0;
            right: unset;
            bottom: 65px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 var(--default-mobile-padding);
        }
    }

    video {
        height: 100%;
        width: 100vw;
        object-fit: cover;

        @media screen and (max-width: 992px) {
            object-position: -300px;
            height: 100svh;
        }
    }
}
