.headshots {
    margin: 80px auto 160px;
    max-width: var(--default-max-width);
    padding: 0 20px;

    .text {
        color: var(--color-grey);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 620px;
        margin-bottom: 5vh;

        b {
            font-weight: 600;
        }
    }

    .headshots-list {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        justify-content: flex-start;
        gap: 40px;

        .image {
            height: 180px;
            width: 240px;
            border-radius: 4px;
            object-fit: cover;
            object-position: top;
            overflow: hidden;
            opacity: 95%;
        }

        .name {
            margin-top: 15px;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .tel {
            padding-top: 5px;
        }

        a {
            text-decoration: none;
            color: var(--link-dark);
        }
    }

    @media screen and (max-width: 992px) {
        margin: 80px 0 304px;
    }

    @media screen and (max-width: 600px) {
        margin: 40px auto;

        .headshots-list {
            flex-direction: column;
            gap: 40px;

            .image {
                width: 91vw;
                height: 67vw;
            }
        }

        .title {
            max-width: unset;
        }
    }
}
