.related-articles {
    padding: 80px var(--default-desktop-padding);
    background-color: #F8F8F8;

    > h2 {
        text-align: center;
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        padding: 80px var(--default-mobile-padding);
    }

    @media screen and (max-width: 360px) {
        padding: 40px 20px;
    }
}

.wrapper {
    max-width: calc(var(--wide-max-width) + var(--default-desktop-padding) * 2);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > a {
        margin-top: 40px;
        max-width: 100%;

        @media screen and (max-width: 1330px) {
            flex-basis: calc(50% - 20px);
        }

        @media screen and (max-width: 900px) {
            flex-basis: calc(50% - 5px);
        }
    }

    @media screen and (max-width: 600px) {
        justify-content: center;
        flex-direction: column;
    }
}
