@import '../Generic.module';

.grid {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 950px) {
        margin-top: 40px;
        flex-direction: column;
        flex-grow: 1;
        gap: 24px;
    }
}

h2.title {
    margin: 0 0 24px;
}

.button {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 75px;
    padding: 18px 64px;
    border-radius: 8px;
    font-size: 26px;
    text-align: center;
    text-decoration: none;
    justify-content: center;

    > div {
        line-height: 26px;
    }

    .description {
        font-size: 12px;
        font-weight: 200;
        text-align: left;

        @media screen and (max-width: 950px) {
            text-align: center;
        }
    }

    @media screen and (max-width: 950px) {
        width: 100%;
        padding: 12px 24px;
    }
}

.icon {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;

    svg {
        position: absolute;
        left: 24px;
        top: 52%;
        transform: translateY(-50%);
        width: 24px;
        height: auto;
    }
}

.solid {
    background: #000;
    color: var(--color-white);
}

.text {
    background: none;
    color: var(--color-black);
}
