.body {
  font-size: 18px;
  font-weight: 200;
  line-height: 1.6;
}

.section {
  margin: 0 auto;
  display: flex;
  background-color: var(--color-white); // TODO: Not here?
  min-height: 540px;
}

.section > div {
  width: 50%;
  padding: 80px;
}

.section > div > div {
  max-width: calc((1320px/2) - 80px);
}

.section > div > div > h2 {
  margin-top: 0;
}

.section > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.section > div:last-child {
  display: flex;
  justify-content: flex-start;
}

.button {
  vertical-align: bottom;
  margin-top: 20px;
  display: inline-block;
  color: var(--color-coal);
  padding: 0.7rem 1.2rem 0.9rem 1.6rem;
  border-radius: 50px;
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 2px solid var(--color-coal);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-coal);

    .icon {
      color: var(--color-white);
    }
  }
}

.icon {
  vertical-align: bottom;
  margin-left: 10px;
  color: var(--color-coal);
}

.image {
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 900px) {
  .section {
    flex-direction: column;
    min-height: unset;
  }

  .section > div {
    justify-content: center !important;
    width: 100%;
  }

  .image {
    order: -1;
  }

  .image {
    min-height: 540px;
  }
}

@media screen and (max-width: 1200px){
    .section > div {
        padding: 40px;
    }
}
